import {
  FETCH_PRODUCT_LIST_START,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILURE,
  FETCH_MORE_PRODUCT_LIST_START,
  FETCH_SINGLE_PRODUCT_START,
  FETCH_SINGLE_PRODUCT_SUCCESS,
  FETCH_SINGLE_PRODUCT_FAILURE,
  FETCH_PRODUCT_REVIEW_LIST_START,
  FETCH_PRODUCT_REVIEW_LIST_SUCCESS,
  FETCH_PRODUCT_REVIEW_LIST_FAILURE,
  FORCE_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_WISHLIST_LIST_START,
  FETCH_PRODUCT_WISHLIST_LIST_SUCCESS,
  FETCH_PRODUCT_WISHLIST_LIST_FAILURE,
  SAVE_WISHLIST_START,
  SAVE_WISHLIST_SUCCESS,
  SAVE_WISHLIST_FAILURE,
  FETCH_PRODUCT_SUGGETION_START,
  FETCH_PRODUCT_SUGGETION_FAILURE,
  FETCH_PRODUCT_SUGGETION_SUCCESS,
  FORCE_PRODUCT_WISHLIST_LIST_SUCCESS,
  FORCE_FETCH_PRODUCT_REVIEW_LIST_SUCCESS,
  FETCH_MORE_PRODUCT_REVIEW_LIST_START,
} from "../actions/ActionConstant";


const initialState = {
  productList: {
    data: {
      products: [],
      total_products: 0,
      total_in_stock_products: 0,
      total_out_of_stock_products: 0,
      seller: {}
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleProduct: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  productReviews: {
    data: {
      reviews: [],
      total_reviews: 0,
    },
    loading: true,
    loadMore: false,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  productWishlists: {
    data: {
      products: [],
      total_products: 0,
    },
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  wishlistSave: {
    data: {},
    loading: true,
    error: false,
    loadingButtonContent: "",
    buttonDisable: false
  },
  productSuggetions: {
    data: {},
    loading: true,
    error: false,
  },
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_LIST_START:
      return {
        ...state,
        productList: {
          data: {
            ...state.productList.data,
            products: [],
            total_products: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case FETCH_MORE_PRODUCT_LIST_START:
      return state;
    case FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: {
          data: {
            products: [...state.productList.data.products, ...action.data.products],
            total_products: action.data.total_products,
            total_in_stock_products: action.data.total_in_stock_products,
            total_out_of_stock_products: action.data.total_out_of_stock_products,
            seller: action.data.seller,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FORCE_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      }
    case FETCH_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        productList: {
          data: state.productList.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_SINGLE_PRODUCT_START:
      return {
        ...state,
        singleProduct: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        },
      };
    case FETCH_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        singleProduct: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        singleProduct: {
          data: {},
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_PRODUCT_REVIEW_LIST_START:
      return {
        ...state,
        productReviews: {
          data: {
            reviews: [],
            total_reviews: 0,
          },
          loading: true,
          loadMore: false,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_MORE_PRODUCT_REVIEW_LIST_START:
      return {
        ...state,
        productReviews: {
          ...state.productReviews,
          loadMore: true,
        }
      }
    case FORCE_FETCH_PRODUCT_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        productReviews: {
          data: action.data,
          loading: false,
          loadMore: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: "Loading",
        }
      }
    case FETCH_PRODUCT_REVIEW_LIST_SUCCESS:
      return {
        ...state,
        productReviews: {
          data: {
            reviews: [...state.productReviews.data.reviews, ...action.data.reviews],
            total_reviews: action.data.total_reviews,
          },
          loading: false,
          loadMore: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_PRODUCT_REVIEW_LIST_FAILURE:
      return {
        ...state,
        productReviews: {
          data: state.productReviews.data,
          loading: false,
          loadMore: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FETCH_PRODUCT_WISHLIST_LIST_START:
      return {
        ...state,
        productWishlists: {
          data: {
            products: [],
            total_products: 0,
          },
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading",
        }
      };
    case FETCH_PRODUCT_WISHLIST_LIST_SUCCESS:
      return {
        ...state,
        productWishlists: {
          data: {
            products: [...state.productWishlists.data.products, ...action.data.products],
            total_products: action.data.total_products,
          },
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case FORCE_PRODUCT_WISHLIST_LIST_SUCCESS:
      return {
        ...state,
        productWishlists: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      }
    case FETCH_PRODUCT_WISHLIST_LIST_FAILURE:
      return {
        ...state,
        productWishlists: {
          data: state.productWishlists.data,
          loading: false,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        }
      };
    case SAVE_WISHLIST_START:
      return {
        ...state,
        wishlistSave: {
          data: action.data,
          loading: true,
          error: false,
          loadingButtonContent: "Uploading....",
          buttonDisable: true
        },
      };
    case SAVE_WISHLIST_SUCCESS:
      return {
        ...state,
        wishlistSave: {
          data: action.data,
          loading: false,
          error: false,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case SAVE_WISHLIST_FAILURE:
      return {
        ...state,
        wishlistSave: {
          data: {},
          loading: false,
          error: action.error,
          loadingButtonContent: null,
          buttonDisable: false
        },
      };
    case FETCH_PRODUCT_SUGGETION_START:
      return {
        ...state,
        productSuggetions: {
          data: {},
          loading: true,
          error: false,
        }
      };
    case FETCH_PRODUCT_SUGGETION_SUCCESS:
      return {
        ...state,
        productSuggetions: {
          data: action.data,
          loading: false,
          error: false,
        }
      };
    case FETCH_PRODUCT_SUGGETION_FAILURE:
      return {
        ...state,
        productSuggetions: {
          data: {},
          loading: false,
          error: action.error,
        }
      };
    default:
      return state;
  }
}

export default ProductReducer;