import React from 'react';
import {
  deleteCartDetailsStart,
  saveCartDetailsStart,
} from "../store/actions/CartsAction";
import { Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NumericInput from "react-numeric-input";
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from "react-multi-lang";
import CustomLazyLoad from '../Helper/CustomLazyLoad';
import { saveWishlistsStart } from '../store/actions/ProductAction';


const CartItem = (props) => {
  const t = useTranslation();
  const { cart } = props;
  const updateCartQty = (productId, qty) => {
    props.dispatch(saveCartDetailsStart({
      product_id: productId,
      quantity: qty,
    }));
  }
  const removeUserCart = (productId) => {
    props.dispatch(deleteCartDetailsStart({ product_id: productId }));
  }

  const wishListToggle = productUniqueId => {
    props.dispatch(saveWishlistsStart({
      product_unique_id: productUniqueId
    }));
  }
  return (
    <div className="cart-list-card">
      <div className="cart-list-left-sec">
        <Link to={`/product/${cart.product.product_unique_id}`}>
          <div className="cart-list-img-sec">
            {/* <Image
            className="cart-list-img"
            src={cart.product.file}
          /> */}
            <CustomLazyLoad
              src={cart.product.file}
              placeholderSrc={window.location.origin + "/images/loading.svg"}
              classes="cart-list-img"
            />
          </div>
        </Link>
        <div className="cart-list-info">
          <Link
            to={`/product/${cart.product.product_unique_id}`}
            className="text-decoration-none">
            <h4>{cart.product.name}</h4>
          </Link>
          <p>
            {t("category")} <span>{cart.product.category_name}</span>
          </p>
          {cart.product.seller ?
            <p>{t("seller")} <Link to={`/shop?seller=${cart.product.seller.seller_unique_id}`}>
              {cart.product.seller.name}
            </Link>
            </p>
            : null
          }
          <p>
            {t("vol")} <span>{cart.product.gross_weight_formatted}</span>
          </p>
          {cart.product.stock_status === 1
            ? <p>
              {t("qty")}
              <span>
                <NumericInput
                  mobile
                  className="form-control"
                  value={cart.quantity}
                  onChange={val => updateCartQty(cart.product.product_id, val)}
                  min={1}
                  max={cart.product.quantity}
                  strict={true}
                />
              </span>
            </p>
            : <p className="text-danger">{cart.product.stock_availablity_formatted}</p>
          }
        </div>
      </div>
      <div className="cart-list-right-sec">
        <div className="cart-list-amout-card">
          <div className="cart-amount-info">
            <h4>{cart.product.selling_price_formatted}</h4>
            {cart.product.discount_tag
              ? <p>
                {cart.product.discount_tag} | <del>{cart.product.original_price_formatted}</del>
              </p>
              : null
            }
          </div>
          <div className="cart-action-btn-sec">
            <Button
              className="cart-remove-btn"
              onClick={() => removeUserCart(cart.product.product_id)}
            >{t("remove")}</Button>
            <Button
              className="wishlist-btn"
              onClick={() => wishListToggle(cart.product.product_unique_id)}
              disabled={props.wishlistSave.buttonDisable &&
                props.wishlistSave.data.product_unique_id === cart.product.product_unique_id}
            >
              {props.wishlistSave.loadingButtonContent &&
                props.wishlistSave.data.product_unique_id === cart.product.product_unique_id
                ? props.wishlistSave.loadingButtonContent
                : cart.product.is_wishlisted === 1 ? t("remove_from_wishlist") : t("add_to_wishlist")
              }
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

}

const mapStateToPros = (state) => ({
  cartList: state.carts.cartList,
  wishlistSave: state.product.wishlistSave
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(CartItem));