import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import {
  deleteCartDetailsStart,
  guestCartList,
  saveCartDetailsStart,
} from "../store/actions/CartsAction";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { withTranslation, useTranslation } from "react-multi-lang";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { saveWishlistsStart } from "../store/actions/ProductAction";
import ThcProgress from "./ThcProgress";
import CbdProgress from "./CbdProgress";

const ProductCard = (props) => {
  const navigate = useNavigate();
  const { product } = props;
  const t = useTranslation();

  const { showSeller = true } = props;
  const [isCarted, setIsCarted] = useState(false);

  useEffect(() => {
    if (
      props.guestCartList.filter(
        (cartProduct) => cartProduct.product_id === product.product_id
      ).length > 0
    ) {
      setIsCarted(true);
    } else {
      setIsCarted(false);
    }
  }, [product, props.guestCartList]);

  const onCartClickUser = (e) => {
    e.stopPropagation();
    if (!props.cartSave.buttonDisable && !props.cartDelete.buttonDisable) {
      if (product.is_carted === 0) {
        props.dispatch(
          saveCartDetailsStart({
            product_id: product.product_id,
            quantity: 1,
          })
        );
      } else {
        props.dispatch(
          deleteCartDetailsStart({
            product_id: product.product_id,
          })
        );
      }
    }
  };

  const onGuestCartClick = (e) => {
    e.preventDefault();
    let cartItems = [];
    if (!isCarted) {
      const cartProduct = {
        product_id: product.product_id,
        quantity: 1,
      };
      cartItems = [...props.guestCartList, cartProduct];
    } else {
      cartItems = props.guestCartList.filter(
        (cartedProduct) => cartedProduct.product_id !== product.product_id
      );
    }
    localStorage.setItem("carts", JSON.stringify(cartItems));
    props.dispatch(guestCartList(cartItems));
  };

  const wishListToggle = (e) => {
    e.preventDefault();
    if (Object.keys(props.profile.data).length > 0) {
      props.dispatch(
        saveWishlistsStart({
          product_unique_id: product.product_unique_id,
        })
      );
    } else {
      localStorage.setItem("product", product.product_unique_id);
      navigate("/login");
    }
  };

  return (
    <div className="product-search-result-grid-card">
      <Link
        to={`/product/${product.product_unique_id}`}
        className="no-link"
      >
        <div className="product-search-result-img-sec">
          {/* <Image
            className="product-search-result-img"
            src={product.file}
            type="image/png"
          /> */}
          <CustomLazyLoad
            src={product.file}
            classes="product-search-result-img"
            placeholderSrc={window.location.origin + "/images/loading.svg"}
            alt={product.name}
          />
        </div>
        {product.discount_tag ? (
          <div className="product-offer-sec">{product.discount_tag}</div>
        ) : null}
        <Link
          to="#"
          className="product-search-cart-icon-sec"
          onClick={wishListToggle}
        >
          {product.is_wishlisted ? (
            <Image
              className="product-search-cart-icon"
              src={window.location.origin + "/images/like-fill.svg"}
            />
          ) : (
            <Image
              className="product-search-cart-icon"
              src={window.location.origin + "/images/like.svg"}
            />
          )}
        </Link>
        <div className="product-cbd-value">
          <div className="product-percentage">
            {product.thc_drug_content ?
              <div className="pack-icon">
                <ThcProgress percentage={product.thc_avg} width="40px" height="40px" fontsize="0.8em" />
                <div className="pack-value">{product.thc_formatted ? product.thc_formatted : '0%'}</div>
              </div>
              : null
            }
            {product.cbd_drug_content ?
              <div className="pack-icon">
                <CbdProgress percentage={product.cbd_avg} width="40px" height="40px" fontsize="0.8em" />
                <div className="pack-value">{product.cbd_formatted ? product.cbd_formatted : '0%'}</div>
              </div>
              : null
            }
          </div>
        </div>
        <div className="product-search-result-info">
          <h4>{product.name}</h4>
          <p>{product.sub_category_name}</p>
          {product.seller && showSeller ?
            <p>
              <Link to={`/shop?seller=${product.seller.seller_unique_id}`}>
                {product.seller.name}
              </Link>
            </p>
            : null
          }
          <h3>
            {product.selling_price_formatted}{" "}
            {product.discount_tag ? (
              <sub>
                <del>{product.original_price_formatted}</del>
              </sub>
            ) : null}
          </h3>
          <div className="product-thc-value">
            <h4>{product.plant_type}</h4>
          </div>
          {/* <ReactStars
            count={5}
            value={product.review_stars}
            size={24}
            isHalf={true}
            edit={false}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          /> */}
          {product.stock_status === 1 ? (
            <div className="default-btn-sec">
              {localStorage.getItem("userId") &&
                Object.keys(props.profile.data).length > 0 ? (
                <Link to="#" onClick={onCartClickUser} className="default-btn">
                  {props.cartSave.loadingButtonContent &&
                    props.cartSave.data.product_id === product.product_id
                    ? props.cartSave.loadingButtonContent
                    : props.cartDelete.loadingButtonContent &&
                      props.cartDelete.data.product_id === product.product_id
                      ? props.cartDelete.loadingButtonContent
                      : product.is_carted === 0
                        ? t("add_to_cart")
                        : t("remove_from_cart")}
                </Link>
              ) : (
                <Link to="#" onClick={onGuestCartClick} className="default-btn">
                  {!isCarted ? t("add_to_cart") : t("remove_from_cart")}
                </Link>
              )}
            </div>
          ) : (
            <div className="text-danger">
              {product.stock_availablity_formatted}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

const mapStateToPros = (state) => ({
  profile: state.users.profile,
  guestCartList: state.carts.guestCartList,
  cartSave: state.carts.cartSave,
  cartDelete: state.carts.cartDelete,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ProductCard));
