import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form, Image, Button } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-multi-lang";
import "./OrderTracking.css";
import { Link, Navigate, useParams } from "react-router-dom";
import { fetchOrdersDetailsStart, ordersSendCancelRequestStart, ordersSendReturnRequestStart } from "../store/actions/OrdersAction";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { invoiceOrdersStart } from '../store/actions/OrdersAction';
import { apiUrl } from "../Environment";
import OrderReturnModal from "./OrderReturnModal";


const OrderTrackingIndex = (props) => {
  const t = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState();
  const [orderReturnModal, setOrderReturnModal] = useState(false);

  useEffect(() => {
    props.dispatch(fetchOrdersDetailsStart({
      order_unique_id: params.orderUniqueId,
      order_product_unique_id: params.orderProductUniqueId,
    }));
  }, [params.orderUniqueId, params.orderProductUniqueId]);

  const closeOrderReturnModal = () => {
    setOrderReturnModal(false);
  }

  const handleBack = () => {
    navigate(-1);
  }

  const download = () => {
    const url = apiUrl + "orders_invoice";
    let formData = new FormData();
    formData.append("id", localStorage.getItem("userId"));
    formData.append("token", localStorage.getItem("accessToken"));
    formData.append("order_unique_id", props.orderDetails.data.order.order_unique_id);
    formData.append("invoice_via", "download");
    fetch(url, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `order_${props.orderDetails.data.order.order_unique_id}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelOrder = () => {
    props.dispatch(ordersSendCancelRequestStart({
      order_unique_id: props.orderDetails.data.order.order_unique_id,
      order_product_unique_id: params.orderProductUniqueId,
    }));
  }


  return (
    <div className="order-tracking-sec">
      <Container>
        {props.orderDetails.loading ?
          <>
            <Skeleton count={2} height={100} className='mb-2' />
            <Skeleton count={2} height={200} className='mb-2' />
          </>
          : props.orderDetails.data.order && props.orderDetails.data.order_product ?
            (<Row>
              <Col md={12}>
                <div className="order-tracking-header">
                  <div className="order-tracking-header-info" >
                    <Button className="back-btn" onClick={() => handleBack()}>
                      <Image src={window.location.origin + "/images/back-button.svg"} />
                    </Button>
                    <h4>{t("tracking_order_number")} {props.orderDetails.data.order.order_unique_id}</h4>
                  </div>
                  <div className="order-tracking-action-btn">
                    {/* <Button className="download-receipt-btn" onClick={() => download()}>
                      {t("download_receipt")}
                    </Button> */}
                    {props.orderDetails.data.order_product.order_tracking.status < 4 ?
                      <Button
                        disabled={props.cancelOrder.buttonDisabled}
                        onClick={() => cancelOrder()}
                        className="download-receipt-btn"
                      >
                        {props.cancelOrder.loadingButtonContent ? props.cancelOrder.loadingButtonContent : t("cancel_order")}
                      </Button>
                      : props.orderDetails.data.order_product.order_tracking.status === 5 &&
                        props.orderDetails.data.order.is_return_available ?
                        <Button
                          disabled={props.returnOrder.buttonDisabled}
                          onClick={() => setOrderReturnModal(true)}
                          className="download-receipt-btn"
                        >
                          {props.returnOrder.loadingButtonContent ? props.returnOrder.loadingButtonContent : t("return_order")}
                        </Button>
                        : null
                    }
                  </div>
                </div>
                <div className="order-tracking-status-box">
                  <div className="order-tracking-status-card">
                    <span>{t("shipped_via")}</span>
                    Poshmarkets
                  </div>
                  <div className="order-tracking-status-card">
                    <span>{t("status")}</span>
                    {props.orderDetails.data.order.status_formatted}
                  </div>
                  <div className="order-tracking-status-card">
                    <span>{t("expected_date")}</span>
                    {props.orderDetails.data.order.expected_at_formatted}
                  </div>
                </div>
                <div className="order-tracking-summary-sec">
                  <div className="address-details">
                    <h3>{t("deliver_to")}</h3>
                    <h4>{props.orderDetails.data.order.delivery_address.name}</h4>
                    <p className="address-block">
                      {props.orderDetails.data.order.delivery_address.address}{"\n"}
                      {props.orderDetails.data.order.delivery_address.city}{" "}
                      {props.orderDetails.data.order.delivery_address.state}{" "}
                      {props.orderDetails.data.order.delivery_address.pincode}{"\n"}
                      {props.orderDetails.data.order.delivery_address.landmark}{"\n"}
                      {props.orderDetails.data.order.delivery_address.contact_number}
                    </p>
                  </div>
                  <div className="order-tracking-amount-sec">
                    <h3>{t("order_summary")}</h3>
                    <div className="cart-summary-info">
                      <div className="cart-summary-header">
                        <p>
                          {t("cart_total")}<span>{props.orderDetails.data.order.order_amount.order_total_amount}</span>
                        </p>
                        <p>
                          {t("discount")} <span>-{props.orderDetails.data.order.order_amount.order_discount_total}</span>
                        </p>
                      </div>
                      <div className="cart-summary-total-amount">
                        <h5>
                          {t("order_total")} <span>{props.orderDetails.data.order.order_amount.order_checkout_amount}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md={12} xl={4}>
                    <div className="order-tracking-box">
                      {/* <ul className="order-tracking-progressbar">
                                    <li className="active step0">Confirmed Order</li>
                                    <li className="step0">Processing Order</li>
                                    <li className="step0">Product Dispatched</li>
                                    <li className="step0">Product Delivered</li>
                                </ul> */}
                      <ul className="order-tracking-progress">
                        <li className="progress-item progress-item-completed progress-item-active">
                          <p className="progress-info">
                            {t("confirmed_order")}
                            <div>{props.orderDetails.data.order_product.order_tracking.received_at_formatted}</div>
                          </p>
                        </li>
                        {props.orderDetails.data.order_product.order_tracking.status >= 6 &&
                          !props.orderDetails.data.order_product.order_tracking.awaiting_pickup_at_formatted ?
                          null
                          : <li className={`progress-item 
                          ${props.orderDetails.data.order_product.order_tracking.status >= 2 ? "progress-item-completed progress-item-active" : ""}
                          `}>
                            <p className="progress-info">{t("processing_order")}</p>
                            <div>{props.orderDetails.data.order_product.order_tracking.awaiting_pickup_at_formatted}</div>
                          </li>
                        }
                        {props.orderDetails.data.order_product.order_tracking.status >= 6 &&
                          !props.orderDetails.data.order_product.order_tracking.awaiting_shipment_at_formatted ?
                          null
                          : <li className={`progress-item 
                          ${props.orderDetails.data.order_product.order_tracking.status >= 3 ? "progress-item-completed progress-item-active" : ""}
                          `}>
                            <p className="progress-info">{t("product_dispatched")}</p>
                            <div>{props.orderDetails.data.order_product.order_tracking.awaiting_shipment_at_formatted}</div>
                          </li>
                        }
                        {props.orderDetails.data.order_product.order_tracking.status >= 6 &&
                          !props.orderDetails.data.order_product.order_tracking.shipped_at_formatted ?
                          null
                          : <li className={`progress-item 
                          ${props.orderDetails.data.order_product.order_tracking.status >= 4 ? "progress-item-completed progress-item-active" : ""}
                          `}>
                            <p className="progress-info">{t("product_shipped")}</p>
                            <div>{props.orderDetails.data.order_product.order_tracking.shipped_at_formatted}</div>
                          </li>
                        }
                        {props.orderDetails.data.order_product.order_tracking.status >= 6 &&
                          !props.orderDetails.data.order_product.order_tracking.completed_at_formatted ?
                          null
                          : <li className={`progress-item 
                          ${props.orderDetails.data.order_product.order_tracking.status >= 5 ? "progress-item-completed progress-item-active" : ""}
                          `}>
                            <p className="progress-info">{t("product_delivered")}</p>
                            <div>{props.orderDetails.data.order_product.order_tracking.completed_at_formatted}</div>
                          </li>
                        }
                        {props.orderDetails.data.order_product.order_tracking.status >= 6
                          ? <>
                            {props.orderDetails.data.order_product.order_tracking.cancelled_at_formatted ?
                              <li className="progress-item cancel progress-item-active">
                                <p className="progress-info">{t("cancelled")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.cancelled_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.return_initiated_at_formatted ?
                              <li className="progress-item return progress-item-active">
                                <p className="progress-info">{t("return")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.return_initiated_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.return_rejected_at_formatted ?
                              <li className="progress-item cancel progress-item-active">
                                <p className="progress-info">{t("return_rejected")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.return_rejected_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.return_accepted_at_formatted ?
                              <li className="progress-item success progress-item-active">
                                <p className="progress-info">{t("return_accepted")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.return_accepted_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.return_user_damage_at_formatted ?
                              <li className="progress-item cancel progress-item-active">
                                <p className="progress-info">{t("pickup_rejected")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.return_user_damage_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.return_success_at_formatted ?
                              <li className="progress-item success progress-item-active">
                                <p className="progress-info">{t("pickup_completed")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.return_success_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.refund_initiated_at_formatted ?
                              <li className="progress-item return progress-item-active">
                                <p className="progress-info">{t("refund_initiated")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.refund_initiated_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.refund_failed_at_formatted ?
                              <li className="progress-item cancel">
                                <p className="progress-info">{t("refund_failed")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.refund_failed_at_formatted}</div>
                              </li>
                              : null
                            }
                            {props.orderDetails.data.order_product.order_tracking.refund_success_at_formatted ?
                              <li className="progress-item success progress-item-active">
                                <p className="progress-info">{t("refund_completed")}</p>
                                <div>{props.orderDetails.data.order_product.order_tracking.refund_success_at_formatted}</div>
                              </li>
                              : null
                            }
                          </>
                          : null
                        }
                      </ul>
                    </div>
                  </Col>
                  <Col md={12} xl={8}>
                    <div className="order-detail-item">
                      {/* {props.orderDetails.data.order_product.map((products, i) => ( */}
                        <div className="order-detail-box">
                          <div className="order-product-details">
                            <div className="checkout-product-img">
                              <Image
                                className="checkout-img"
                                src={props.orderDetails.data.order_product.product.file}
                                type="image/png"
                              />
                            </div>
                            <div className="checkout-product-content">
                              <h4>{props.orderDetails.data.order_product.product.name}
                              </h4>
                              <p>
                                {t("category")}<span>{props.orderDetails.data.order_product.product.category_name}</span>
                              </p>
                              <p>
                                {t("vol")}<span>{props.orderDetails.data.order_product.product.gross_weight_formatted}</span>
                              </p>
                              <p>
                                {t("qty")}<span>{props.orderDetails.data.order_product.quantity}</span>
                              </p>
                            </div>
                          </div>
                          <div className="order-pricing-details">
                            <div className="checkout-product-content">
                              <h4>{props.orderDetails.data.order_product.product.selling_price_formatted}</h4>
                              <h5>
                                {props.orderDetails.data.order_product.product.discount_tag}
                                {props.orderDetails.data.order_product.discount_tag ? <span>{props.orderDetails.data.order_product.product.original_price_formatted}</span> : null}
                              </h5>
                            </div>
                          </div>
                        </div>
                      {/* ))} */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            )
            : <NoDataFound />
        }
      </Container>
      {orderReturnModal ?
        <OrderReturnModal
          orderReturnModal={orderReturnModal}
          closeOrderReturnModal={closeOrderReturnModal}
          orderUniqueId={props.orderDetails.data.order.order_unique_id}
          orderProductUniqueId={params.orderProductUniqueId}
        />
        : null
      }
    </div>
  );
};


const mapStateToPros = (state) => ({
  orderDetails: state.orders.orderDetails,
  cancelOrder: state.orders.cancelOrder,
  returnOrder: state.orders.returnOrder,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(OrderTrackingIndex));
