import React, { useState } from "react";
import "./Product.css";
import ProductCard from "./ProductCard";

const TopSellingProductIndex = (props) => {

  return (
    <>
      <div className="top-selling-product-sec">
        <div className="top-selling-product-grid-box">
          {props.products.map((product, i) =>
            <ProductCard product={product} key={i} showSeller={false} />
          )}
        </div>
      </div>
    </>
  );
};

export default TopSellingProductIndex;
